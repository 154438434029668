import React from 'react'
import './About.scss'

import { motion } from 'framer-motion'
import { bios } from '../../../Data'
import avatar from '../../../assets/avatar.jpg'
const About = () => {

	return (
		<motion.div 
    className='container ' id='about'>
			<motion.div
				initial={{ opacity: 0 }}
				whileInView={{ y: [-50, 0], opacity: 1 }}
        viewport={{
          once: true,
          amount: 0.2
        }}
				className='title'
			>
				<span>Who Am I?</span>
				<h1>About Me</h1>
			</motion.div>

			<div className='about_container'>
				<motion.div
					initial={{ x: 0, opacity: 0 }}
					whileInView={{ x: [-250, 0], opacity: 1 }}
					transition={{ duration: 1 }}
          viewport={{
            once: true,
            amount: 0.2
          }}
					className='about_left'
				>
					<img src={avatar} alt='avatar' />
				</motion.div>
				<motion.div
         viewport={{
          once: true,
          amount: 0.2
        }}
					className='about_right'
					initial={{ x: 0, opacity: 0 }}
					whileInView={{ x: [250, 0], opacity: 1 }}
					transition={{ duration: 1 }}
				>
					<p>
						Front-end developer in JavaScript, TypeScript, React and Redux<br></br>
						Inspired by the creation of innovative web products
					</p>
                <div>
              <div className='bio'>
								<span className='bioKey'>
									{bios[0].icon}
									{bios[0].key}
								</span>
								<span className='bioValue'>{bios[0].value}</span>
							</div>
              <div className='bio'>
								<span className='bioKey'>
									{bios[1].icon}
									{bios[1].key}
								</span>
								<span className='bioValue'><a href="mailto:d.pryshchenko@gmail.com">d.pryshchenko@gmail.com</a></span>
							</div>
              </div>
          <a
						href="./cv.pdf"
            target="_blank"
					>
						Download CV
					</a>
				</motion.div>
			</div>
		</motion.div>
	)
}

export default About
