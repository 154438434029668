import React from 'react'
import avatar from '../../../assets/avatar.jpg'
import './Home.scss'
import { motion } from 'framer-motion'

const textAnimation = {
  hidden: {
    y: -150,
    opacity: 0,
  },
  visible: custom => ({
    y: 0,
    opacity: 1,
    transition: { delay: custom * 0.2 }
  }),
}
const textAnimation2 = {
  hidden: {
    x: -150,
    opacity: 0,
  },
  visible: custom => ({
    x: 0,
    opacity: 1,
    transition: { delay: custom * 0.2 }
  }),
}

const Home = () => {
	return (
		<motion.div
			className='container '
			id='home'
			initial='hidden'
      whileInView='visible'
      viewport={{ once: true }}
		>
			<motion.div className='profile'>
				<motion.img 
        variants={textAnimation2} 
        custom={1}
        src={avatar} 
        alt='portfolio' />
			</motion.div>
			<div className='profile_text'>
				<motion.h3 variants={textAnimation} 
          custom={2}
          className='name'>
					Hi, I'm <span>Danylo Pryshchenko</span>
				</motion.h3>
				<motion.span 
        variants={textAnimation} 
        custom={0.3}
        className='text'>
					Driven
					<br /> to craft innovative <br /> web products
				</motion.span>

				<motion.a
					href='https://t.me/pdanylo'
          target='_blank'
          rel="noreferrer"
          variants={textAnimation2} 
          custom={4}
				>
					connect with me
				</motion.a>

				<motion.div 
        variants={textAnimation2} 
        custom={2}
        className='web'>Front-end Developer</motion.div>
			</div>
		</motion.div>
	)
}

export default Home
