import {
	FaUser,
	FaPaperPlane,
	FaGithub,
	FaTelegram,
} from 'react-icons/fa'

import PizzaShop from './assets/pizza-shop.png'
import GetLocationIP from './assets/getLocationIP.png'
import HabbitsVanilla from './assets/Habbits-vanilla-js.png'
import BookApp from './assets/BookApp.js-OOP.png'
import ReactJournal from './assets/simple-react-journal.png'

export const navLinks = ['home', 'about', 'portfolio']

export const socialIcons = [
	{
		id: 1,
		icon: <FaGithub />,
		href: 'https://github.com/pryshchenko'
	},
	{
		id: 2,
		icon: <FaTelegram />,
		href: 'https://t.me/pdanylo'
	},
]

export const bios = [
	{
		id: 1,
		icon: <FaUser />,
		key: 'Name',
		value: 'Pryshchenko Danylo'
	},
	{
		id: 2,
		icon: <FaPaperPlane />,
		key: 'Email',
		value: 'd.pryshchenko@gmail.com'
	}
]

export const workImages = [
	{
		id: 1,
		img: PizzaShop,
		name: 'Pizza-Shop',
		git: 'https://github.com/pryshchenko/pizza-shop',
		host: 'https://pryshchenko.github.io/pizza-shop/',
		category: 'React + Redux Toolkit'
	},
	{
		id: 2,
		img: GetLocationIP,
		git: 'https://github.com/pryshchenko/GetLocationByIP',
		host: 'https://pryshchenko.github.io/GetLocationByIP/',
		name: 'Get location by IP',
		category: 'JS + API'
	},
	{
		id: 3,
		img: HabbitsVanilla,
		git: 'https://github.com/pryshchenko/Habbit-vanila-js',
		host: 'https://pryshchenko.github.io/Habbit-vanila-js/',
		name: 'A simple organizer using vanilla js',
		category: 'JS'
	},
	{
		id: 4,
		img: BookApp,
		git: 'https://github.com/pryshchenko/BookApp.js-OOP',
		host: 'https://pryshchenko.github.io/BookApp.js-OOP/',
		name: 'Book Library JS OOP + using API',
		category: 'JS + API'
	},
	{
		id: 5,
		img: ReactJournal,
		git: 'https://github.com/pryshchenko/simple-react-journal',
		host: 'https://pryshchenko.github.io/simple-react-journal/',
		name: 'Personal journal in simple React',
		category: 'React'
	}
]

export const workNavs = ['All', 'JS', 'JS + API', "React", 'React + Redux Toolkit']