import React from 'react'
import './Footer.scss'
import { socialIcons } from '../../../Data'
import { motion } from 'framer-motion'

const Footer = () => {
	return (
		<motion.div
			initial={{ opacity: 0 }}
			whileInView={{
				opacity: 1
			}}
			transition={{ duration: 3 }}
      viewport={{
        once: true,
        amount: 0.2
      }}
			className='footer'
		>
			<div className='copyright'>
				<p>
					<span>Copyright © {new Date().getUTCFullYear()} Danylo Pryshchenko</span>
				</p>
			</div>
			<div className='followMe'>
				<div className='social_icons'>
					{socialIcons.map((socialIcon, index) => {
						return (
							<a key={index} href={socialIcon.href} target='_blank' rel="noreferrer">
								{socialIcon.icon}
							</a>
						)
					})}
				</div>
			</div>
		</motion.div>
	)
}

export default Footer
